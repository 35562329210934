// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-us-js": () => import("./../../../src/pages/AboutUs.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-agile-testing-js": () => import("./../../../src/pages/AgileTesting.js" /* webpackChunkName: "component---src-pages-agile-testing-js" */),
  "component---src-pages-business-testing-js": () => import("./../../../src/pages/BusinessTesting.js" /* webpackChunkName: "component---src-pages-business-testing-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/Career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/Contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cross-browser-testing-js": () => import("./../../../src/pages/CrossBrowserTesting.js" /* webpackChunkName: "component---src-pages-cross-browser-testing-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mobile-app-testing-js": () => import("./../../../src/pages/MobileAppTesting.js" /* webpackChunkName: "component---src-pages-mobile-app-testing-js" */),
  "component---src-pages-security-testing-js": () => import("./../../../src/pages/SecurityTesting.js" /* webpackChunkName: "component---src-pages-security-testing-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-tools-js": () => import("./../../../src/pages/Tools.js" /* webpackChunkName: "component---src-pages-tools-js" */)
}

